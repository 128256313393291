<template>
  <v-dialog
    v-model="dialog"
    scrollable
    persistent
    :width="800"
    content-class="update-access-address-dialog">
    <base-card>
      <v-toolbar :color="$vuetify.theme.dark ? 'dark' : 'primary'">
        <v-toolbar-title class="white--text">
          {{ $t('access.updateAccessAddress') }}
        </v-toolbar-title>
        <v-spacer />
        <v-btn color="white" icon @click="dialog = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>

      <v-card-text>
        <v-row>
          <v-col cols="6" class="pb-0">
            <v-text-field
              v-model="streetName"
              :label="$t('address.streetName')"
            />
          </v-col>

          <v-col cols="3" class="pb-0">
            <v-text-field
              v-model="streetNumber"
              :label="$t('address.streetNumber')"
            />
          </v-col>

          <v-col cols="3" class="pb-0">
            <v-text-field
              v-model="streetLittera"
              :label="$t('address.letter')"
            />
          </v-col>

          <v-col cols="6" class="pb-0">
            <v-text-field
              v-model="city"
              :label="$t('address.city')"
            />
          </v-col>

          <v-col cols="6" class="pb-0">
            <v-text-field
              v-model="postalCode"
              :label="$t('address.postalCode')"
            />
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-actions class="justify-center mb-2">
        <v-btn
          class="primary px-4 mr-4"
          :disabled="isLoading"
          @click="updateAddress"
        >
          {{ $t('common.update') }}
        </v-btn>

        <v-btn
          class="error px-4"
          :disabled="isLoading"
          @click.native="dialog = false"
        >
          {{ $t('common.close') }}
        </v-btn>
      </v-card-actions>
    </base-card>
  </v-dialog>
</template>

<script>
  import { mapActions } from 'vuex'

  import util from '@/utils'
  import { showSuccessDialog, showErrorDialog } from '@/utils/swalDialog'

  export default {
    props: {
      value: {
        type: Boolean,
        default: false,
      },

      networkType: {
        type: String,
      },

      address: {
        type: Object,
        default: () => ({}),
      },
    },

    data() {
      return {
        streetName: '',
        streetNumber: '',
        streetLittera: '',
        city: '',
        postalCode: '',
        isLoading: false,
      }
    },

    watch: {
      dialog(value) {
        if (!value) {
          return
        }

        this.streetName = this.address.streetName || ''
        this.streetNumber = this.address.streetNumber || ''
        this.streetLittera = this.address.streetLittera || ''
        this.city = this.address.city || ''
        this.postalCode = this.address.postalCode || ''
      },
    },

    computed: {
      dialog: {
        get() {
          return this.value
        },
        set(v) {
          this.$emit('input', v)
        }
      },
    },

    methods: {
      ...mapActions({
        updateAccessAddress: 'updateAccessAddress',
      }),

      async updateAddress () {
        this.isLoading = true

        try {
          const payload = {
            type: this.networkType,
            id: this.address.id,
            streetName: this.streetName,
            streetNumber: this.streetNumber,
            streetLittera: this.streetLittera,
            city: this.city,
            postalCode: this.postalCode,
          }

          await this.updateAccessAddress(payload)
          this.$emit('refresh')
          showSuccessDialog(this.$t('access.updateAddressSuccessfully'))
          this.dialog = false
        } catch (error) {
          const errorText = util.getErrorResponse(error)
          showErrorDialog(this.$t('common.error'), errorText)
        }
        this.isLoading = false
      },
    }
  }
</script>