<template>
  <v-dialog
    scrollable
    persistent
    :width="500"
    v-model="dialog">
    <base-card>
      <v-toolbar :color="$vuetify.theme.dark ? 'dark' : 'primary'">
        <v-toolbar-title class="white--text" v-text="$t('access.addAccessInfo')" />
        <v-spacer />
        <v-btn color="white" icon @click="dialog = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>

      <v-card-text>
        <v-form
          ref="form"
          v-model="validForm"
          lazy-validation
        >
          <v-row>
            <v-col cols="12" class="pb-0">
              <v-text-field
                v-model="accessId"
                :label="$t('order.accessId')"
                :rules="accessIdRules"
              />
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>

      <v-card-actions class="justify-center mb-2">
        <v-btn
          class="primary px-4 mr-4"
          @click="addAccessInfo"
        >
          {{ $t('common.add') }}
        </v-btn>

        <v-btn
          class="error px-4"
          @click.native="dialog = false">
          {{ $t('common.close') }}
        </v-btn>
      </v-card-actions>
    </base-card>
  </v-dialog>
</template>

<script>
  export default {
    name: 'AddAccessDetailDialog',

    props: {
      value: {
        type: Boolean,
        default: false,
      },
    },

    computed: {
      dialog: {
        get() {
          return this.value
        },
        set(v) {
          this.$emit('input', v)
        }
      },
    },

    data() {
      return {
        accessId: '',
        validForm: true,
        accessIdRules: [
          (v) => !!v || this.$t('error.fieldRequired', { field: this.$t('order.accessId') }),
        ],
      }
    },

    watch: {
      dialog (value) {
        if (value) {
          this.accessId = ''
        }
      }
    },

    methods: {
      addAccessInfo () {
        const isValid = this.$refs.form.validate()

        if (!isValid) {
          return
        }

        this.$emit('add-access-info', this.accessId)
      },
    }
  }
</script>
